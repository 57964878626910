import React, { useState } from 'react';
import { Layer, Box, Text, Button, DateInput } from 'grommet';
import { Clock } from 'grommet-icons';
import moment from 'moment';

const TimeTravel = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  if (loading) return null;
  if (typeof window === 'undefined') return null;
  const travelling = sessionStorage.getItem('now');
  if (!travelling) return null;
  const now = new Date(Date.parse(travelling));
  return (
    <Layer
      position="bottom-left"
      responsive={false}
      margin={{ bottom: 'xlarge' }}
      modal={false}
      background="neutral-1"
    >
      <Box pad="small" direction="row" align="center">
        {!open && (
          <Text onClick={toggleOpen} size="small">
            {moment(now).format('DD/M')}
          </Text>
        )}
        {open && (
          <>
            <Clock onClick={toggleOpen} pad="medium" />
            <Box pad={{ horizontal: 'small' }}>
              <Box direction="row" gap="small">
                <DateInput
                  format="mm/dd/yyyy"
                  value={travelling}
                  onChange={({ value }) => {
                    setLoading(true);
                    location.href = `?now=${value}`;
                  }}
                />
                <Button
                  primary
                  label="Reset"
                  onClick={() => {
                    setLoading(true);
                    sessionStorage.removeItem('now');
                    const params = new URLSearchParams(location.search);
                    params.delete('now');
                    location.href = `${location.pathname}${params.toString()}`;
                  }}
                  alignSelf="end"
                  size="small"
                />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Layer>
  );
};

export default TimeTravel;
